/* eslint-disable import/no-extraneous-dependencies */
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import Base from '../../_config/base';

class Galeryitem extends Base {
  // eslint-disable-next-line no-useless-constructor
  constructor(el) {
    super(el);

    gsap.registerPlugin(ScrollTrigger);

    const galeryItems = [...document.querySelectorAll('.galery-item')];

    galeryItems.forEach((item) => {
      gsap.set(item, {
        y: 50,
      });

      gsap.to(item, {
        scrollTrigger: {
          trigger: el,
          start: 'top 80%',
          end: 'bottom 20%',
        },
        y: 0,
        delay: galeryItems.indexOf(item) * 0.1, // Adding delay based on the index of the item
      });
    });
  }
}

Galeryitem.className = 'Galeryitem';
export default Galeryitem;
