import Base from '../../_config/base';

class Iframe extends Base {
  constructor(el) {
    super(el);
    // const iframeActiveButton = el.querySelector('.iframe__buttonStart');
    // const iframe = el.querySelector('.iframe__iframe');
    // const iframeThumbnail = el.querySelector('.iframe__iframe--thumbnail');
    // // let clickedOnceState = false;
    // const originalSrc = iframe.src;

    // iframeActiveButton.onclick = () => {
    //   iframe.src = originalSrc; // Reset the iframe src to reload it
    //   iframe.classList.remove('iframe__iframe--deactive');
    //   iframe.classList.remove('d-none');
    //   iframeThumbnail.classList.add('d-none');
    //   iframeActiveButton.classList.add('d-none');
    //   clickedOnceState = true;
    // };

    // // check if iframe is in view, if not, add class --deactive and show start button again
    // const observer = new IntersectionObserver((entries) => {
    //   entries.forEach((entry) => {
    //     if (!entry.isIntersecting && clickedOnceState) {
    //       iframe.classList.add('iframe__iframe--deactive');
    //       iframe.classList.add('d-none');
    //       iframeThumbnail.classList.remove('d-none');
    //       iframeActiveButton.classList.remove('d-none');
    //       iframeActiveButton.innerHTML = 'Fortfahren';
    //       iframe.src = ''; // Clear the iframe src to kill the session
    //     }
    //   });
    // });

    // observer.observe(iframe);

    const lightboxActiveButton = el.querySelector('.iframe__buttonStart');
    const lightboxCloseButton = el.querySelector('.twocolumn__lightbox__close');
    const lightbox = el.querySelector('.twocolumn__lightbox');
    const header = document.querySelector('.header');
    const iframe = el.querySelector('.iframe__iframe'); // Assuming there's an iframe inside the lightbox
    const originalSrc = iframe ? iframe.src : '';

    if (lightbox !== null) {
      lightboxActiveButton.onclick = () => {
        if (iframe) {
          iframe.src = originalSrc; // Reload the iframe
        }
        lightbox.classList.remove('d-none');
        header.classList.add('d-none');
      };

      lightboxCloseButton.onclick = () => {
        if (iframe) {
          iframe.src = ''; // Kill the iframe session
        }
        lightbox.classList.add('d-none');
        header.classList.remove('d-none');
      };
    }
  }
}

Iframe.className = 'Iframe';
export default Iframe;
