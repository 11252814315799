import Base from '../../_config/base';

class Twocolumn extends Base {
  constructor(el) {
    super(el);
    const lightboxActiveButton = el.querySelector('.lightbox__buttonStart');
    const lightboxCloseButton = el.querySelector('.twocolumn__lightbox__close');
    const lightbox = el.querySelector('.twocolumn__lightbox');
    const header = document.querySelector('.header');
    const iframe = lightbox.querySelector('.twocolumn__lightbox__iframe'); // Assuming there's an iframe inside the lightbox
    const originalSrc = iframe ? iframe.src : '';

    if (lightbox !== null) {
      lightboxActiveButton.onclick = () => {
        if (iframe) {
          iframe.src = originalSrc; // Reload the iframe
        }
        lightbox.classList.remove('d-none');
        header.classList.add('d-none');
      };

      lightboxCloseButton.onclick = () => {
        if (iframe) {
          iframe.src = ''; // Kill the iframe session
        }
        lightbox.classList.add('d-none');
        header.classList.remove('d-none');
      };
    }
  }
}

Twocolumn.className = 'Twocolumn';
export default Twocolumn;
