/* eslint-disable import/no-extraneous-dependencies */
import { gsap } from 'gsap';
import SplitText from 'gsap/SplitText';
import ScrollTrigger from 'gsap/ScrollTrigger';
import Base from '../../_config/base';

class Scrollinto extends Base {
  // eslint-disable-next-line no-useless-constructor
  constructor(el) {
    super(el);

    const markerSetting = false;

    gsap.registerPlugin(ScrollTrigger, SplitText);

    document.fonts.ready.then(() => {
      const split = new SplitText(el.querySelector('.scrollinto__title'), {
        type: 'lines',
      });

      const splitContent = new SplitText(el.querySelector('.scrollinto__content p'), {
        type: 'lines',
      });

      // Wrap each line in a div with overflow hidden
      splitContent.lines.forEach((line) => {
        const wrapper = document.createElement('div');
        wrapper.style.overflow = 'hidden';
        line.parentNode.insertBefore(wrapper, line);
        wrapper.appendChild(line);
      });

      gsap.set(el, {
        y: -20,
      });

      gsap.to(el, {
        scrollTrigger: {
          trigger: el,
          start: 'top 80%',
          end: 'bottom 20%',
          scrub: 1,
          markers: markerSetting,
        },
        y: 20,
      });

      gsap.from(split.lines, {
        scrollTrigger: {
          trigger: el,
          start: 'top 80%',
          end: 'bottom 20%',
          markers: markerSetting,
        },
        y: 20,
        yPercent: 100,
        stagger: 0.1,
        duration: 2,
        opacity: 0,
        ease: 'expo.out',
      });

      gsap.from(splitContent.lines, {
        scrollTrigger: {
          trigger: el,
          start: 'top 80%',
          end: 'bottom 20%',
          markers: markerSetting,
        },
        y: 20,
        yPercent: 100,
        stagger: 0.1,
        duration: 2,
        opacity: 0,
        ease: 'expo.out',
      });
    });
  }
}

Scrollinto.className = 'Scrollinto';
export default Scrollinto;
