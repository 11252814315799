// import Button from './01-atoms/button/button';
// import Attachment from './01-atoms/inputs/attachment';
// import Range from './01-atoms/inputs/range';
// import Select from './01-atoms/inputs/select';
// import Share from './02-molecules/share/share';
// import Accordion from './02-molecules/accordion/accordion';
// import Alerts from './02-molecules/alerts/alerts';
// import Popup from './02-molecules/popup/popup';
// import Teasers from './02-molecules/threeTeaser/teasers';
// import Filter from './02-molecules/filter/filter';
// import Dialogues from './02-molecules/dialogues/dialogues';
import Galeryitem from './01-atoms/galeryItem/galeryitem';
import Iframe from './02-molecules/iframe/iframe';
import Slider from './02-molecules/slider/slider';
import Twocolumn from './02-molecules/twocolumn/twocolumn';
import Hero from './02-molecules/hero/hero';
import Header from './03-organisms/header/header';
import Scrollsmoother from './03-organisms/scrollanim/scrollsmoother';
import Scrollinto from './03-organisms/scrollanim/scrollinto';

require('./main.scss');

function ready(fn) {
  if (document.attachEvent ? document.readyState === 'complete' : document.readyState !== 'loading') {
    fn();
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
}

ready(() => {
  const html = document.querySelector('html');
  const Component = window.Streusel || {};

  html.classList.remove('no-js');

  // Component.Button = Button.init();
  Component.Scrollsmoother = Scrollsmoother.init();
  // Component.Range = Range.init();
  Component.Twocolumn = Twocolumn.init();
  Component.Scrollinto = Scrollinto.init();
  // Component.Dialogues = Dialogues.init();
  // Component.Share = Share.init();
  // Component.Accordion = Accordion.init();
  // Component.Alerts = Alerts.init();
  Component.Iframe = Iframe.init();
  Component.Galeryitem = Galeryitem.init();
  // Component.Popup = Popup.init();
  Component.Hero = Hero.init();
  Component.Slider = Slider.init();
  // Component.Attachment = Attachment.init();
  // Component.Select = Select.init();
  Component.Header = Header.init();
  // Component.Teasers = Teasers.init();
  // Component.Filter = Filter.init();
});
