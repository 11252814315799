import Base from '../../_config/base';

class Header extends Base {
  constructor(el) {
    super(el);

    this.hamburger = el.querySelector('#hamburger-icon'); // Selects the hamburger icon element
    this.parentItem = el.querySelector('.has-children > a'); // Selects the parent item with children
    this.hamburgerMenu = el.querySelector('.hamburger-menu'); // Selects the hamburger menu element
    this.logo = el.querySelector('.header__logo'); // Selects the logo element
    this.body = document.getElementById('body'); // Selects the body element
    const allAnchorLinks = [...document.querySelectorAll('.linkId')]; // Selects all header link elements
    const header = el; // Assigns the header element
    const headerBtns = [...el.querySelectorAll('.hamburger-menu__item')]; // Selects all hamburger menu items

    if (this.hamburger !== null) {
      this.hamburger.onclick = (e) => {
        e.preventDefault(); // Prevents the default action
        this.hamburger.classList.toggle('active'); // Toggles the active class on the hamburger icon
        this.logo.classList.toggle('header__logo--active'); // Toggles the active class on the logo
        this.body.classList.toggle('overflow-hidden'); // Toggles the overflow-hidden class on the body
        el.classList.toggle('active'); // Toggles the active class on the header
        this.hamburgerMenu.classList.remove('active'); // Removes the active class from the hamburger menu
      };
    }

    if (headerBtns !== null) {
      headerBtns.forEach((btn) => {
        btn.onclick = () => {
          this.hamburger.classList.toggle('active'); // Toggles the active class on the hamburger icon
          this.logo.classList.toggle('header__logo--active'); // Toggles the active class on the logo
          this.body.classList.toggle('overflow-hidden'); // Toggles the overflow-hidden class on the body
          el.classList.toggle('active'); // Toggles the active class on the header
          this.hamburgerMenu.classList.remove('active'); // Removes the active class from the hamburger menu
        };
      });
    }

    if (allAnchorLinks[0] !== null) {
      allAnchorLinks.forEach((link) => {
        link.onclick = () => {
          // if not home page, redirect to homepage and scroll to element
          const linkId = link.getAttribute('linkid'); // Gets the link ID attribute
          if (window.location.pathname !== '/' && window.location.pathname !== '/en/') {
            window.location.href = '/'; // Redirects to the homepage
            return;
          }
          if (window.location.pathname !== '/' && window.location.pathname !== '/en/') {
            window.location.href = '/en'; // Redirects to the English homepage
            return;
          }
          if (!linkId) return; // If no link ID, return
          const element = document.getElementById(linkId); // Gets the element by link ID
          if (!element) return; // If no element, return
          let offset = 100; // Sets the offset for scrolling

          // check if user is on mobile
          if (window.innerWidth < 1024) {
            this.hamburger.classList.toggle('active'); // Toggles the active class on the hamburger icon
            this.logo.classList.toggle('header__logo--active'); // Toggles the active class on the logo
            this.body.classList.toggle('overflow-hidden'); // Toggles the overflow-hidden class on the body
            el.classList.toggle('active'); // Toggles the active class on the header
            this.hamburgerMenu.classList.remove('active'); // Removes the active class from the hamburger menu
            offset = 86; // Sets the offset for mobile
          }

          // scrollToElement
          window.scrollTo({
            top: element.offsetTop - offset, // Scrolls to the element with offset
            behavior: 'smooth', // Smooth scrolling
          });
        };
      });
    }

    if (window.pageYOffset > 5) {
      header.classList.add('activeHeader'); // Adds the activeHeader class if page is scrolled down
    }

    let oldValue = 0;
    window.addEventListener('scroll', () => {
      // Get the new Value
      const newValue = window.pageYOffset;

      // Subtract the two and conclude
      if (newValue < 5) {
        header.classList.remove('activeHeader');
        header.classList.remove('inactiveHeader');
      } else if (oldValue - newValue > 0) {
        // up
        header.classList.add('activeHeader');
        header.classList.remove('inactiveHeader');
      } else if (oldValue - newValue < 0) {
        // down
        header.classList.add('inactiveHeader');
      }

      // Update the old value
      oldValue = newValue;
    });

    const htmlElement = document.getElementById('top');
    htmlElement.style.overscrollBehavior = 'auto';
  }
}

Header.className = 'Header';
export default Header;
