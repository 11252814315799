import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import Base from '../../_config/base';

class Slider extends Base {
  constructor(el) {
    super(el);
    const slider = el.querySelector('.swiper');
    const sliderOpt = {
      // configure Swiper to use modules
      modules: [Navigation, Pagination],
      centeredSlides: true,
      slidesPerView: 1,
      spaceBetween: 64,
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      // Navigation arrows
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    };

    // eslint-disable-next-line
    const swiper = new Swiper(slider, sliderOpt);
  }
}

Slider.className = 'Slider';
export default Slider;
