/* eslint-disable import/no-extraneous-dependencies */
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollSmoother } from 'gsap/ScrollSmoother';
import Base from '../../_config/base';

class Scrollsmoother extends Base {
  constructor(el) {
    super(el);
    gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

    ScrollSmoother.create({
      smooth: 1.2,
      effects: true,
      smoothTouch: 0.1,
    });
  }
}

Scrollsmoother.className = 'Scrollsmoother';
export default Scrollsmoother;
